
import BudouX from '../components/BudouX.vue'
import { defineComponent } from 'vue'
import { workTypes } from '../utils/const'
import moment from 'moment-timezone'
moment.locale('ja')
moment.tz.setDefault('Asia/Tokyo')

export default defineComponent({
	components: {
		BudouX,
	},
	props: ['data', 'details'],
    methods: {
        getHowOld: function (date: string) {
			return Math.floor(moment(date, 'YYYY-MM-DD').diff(moment(), 'day') / -365)
		},
		getTypeJa: function (type: string) {
			for (const w of workTypes) {
				if (w.value === type) return w.label
			}
		},
    }
})
